.Container {
  position: absolute;
}

.DetailsContainer,
.HeaderText,
.LowOpacityText {
  /* margin: -20px -20px -20px -20px; */

  /* height: 100%; */
  /* width: 100%; */
  max-width: 30rem;
  max-height: 30rem;

  padding: 1rem;
  /* box-shadow: -5px -5px 15px #ffffffcb; */
  /* border: 1px solid #b4b4b4; */
  border-radius: 10px;
  background: #eeeff5;
  flex-direction: column;

  font-size: 2rem;
  font-family: 'Circular Std Medium';
  color: #4a4a4a;
}

.LowOpacityText {
  padding-top: 0rem;
  color: #cbcbcf;
}

.DescriptionTextBox {
  font-size: '1.8rem';
  font-family: 'Circular Std Medium';

  color: #4a4a4a;
  background: #9f9f9f;
  padding: 1rem;
  box-shadow: inset -3px -3px 5px rgba(255, 255, 255, 0.1), inset 3px 3px 10px rgba(0, 0, 0, 0.1);
  border: 0px solid #b4b4b4;
  width: 100%;
  outline: none;
  margin-top: 5px;
  /* ::placeholder {
    color: #cbcbcf;
    font-size: 1.2rem;
  } */
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
