.input {
  border: 3rem;
  width: 100%;
  height: min(45px, 100%);
  padding-left: 1.7rem;
  padding-right: 1.7rem;
}

.input .react-datetime-picker__wrapper {
  border-radius: 1rem;
  width: 100%;
  background: #EEEFF5 0% 0% no-repeat padding-box;
  box-shadow: #B4B4B4 1px 1px 6px inset, rgba(255, 255, 255, 0.08) -6px -6px;
  padding: 0.1rem 1rem;
  border: none;
}

.react-datetime-picker__inputGroup{
  height: 4.4rem;
  margin-left: 0.2rem;
}