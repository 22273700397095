.tooltip .tooltiptext {
  display: none;
  min-width: 20rem;
  background-color: #f4f4f8;
  color: black;
  text-align: center;
  border-radius: 4px;
  padding: 5px 0;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s;
  box-shadow: -5px -5px 11px #fffffff5;
}

.tooltip:hover .tooltiptext {
  display: block;
}
