:root {
  --black: #4a4a4a;
  --orange: #ff6610;
  --grey: rgb(205, 205, 205);
  --white: rgb(240, 240, 240);
  --red: rgb(206, 36, 36);
  --light-blue: rgb(102, 193, 255);
  --font-light: 'Circular Std Medium', sans-serif;
  --font-book: 'Circular Std Book', sans-serif;
  --font-black: 'Circular Std Bold', sans-serif;
  color: var(--black);
  font-family: var(--font-light);
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

.calendar {
  box-sizing: border-box;
  font-size: 1rem;
}

.calendar .day-names {
  display: flex;
  flex-wrap: wrap;
  min-width: 30rem;
  height: 2rem;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .calendar .day-names {
    min-width: 10rem;
  }
}

.calendar .header .previous {
  flex: 1;
  text-align: left;
  /* margin-left: 1rem; */
}

.calendar .header .next {
  flex: 1;
  text-align: right;
  /* margin-right: 1rem; */
}

.calendar .week {
  /* background-color: white; */
  width: calc(100% / 7);
  text-align: center;
  text-transform: capitalize;
  color: white;
}
.calendar .week div {
  width: 100%;
}

.calendar .day {
  position: relative;
  width: calc(100% / 7);
  height: 3rem;
  display: inline-block;
  /* background-color: white; */
  z-index: 1;
  text-align: center;
}

.calendar .day div {
  width: 5.4rem;
  height: 4.4rem;
  position: relative;
  /* color: var(--black); */
  z-index: 100;
  line-height: 4.4rem;
}

.calendar .day div.before {
  color: var(--grey);
}

.calendar .day div.holiday {
  color: red;
}

.calendar .day div.selected {
  /* background-color: var(--orange); */
  color: var(--white);
  font-weight: bold;
}

.calendar .day div.today {
  background-color: var(--grey);
}
