#content {
  width: 100%;
}
.react-grid-layout {
  background: #f4f4f8;
  flex: 1;
  overflow-y: auto;
@media print {
  display: block !important;
  width: auto !important;
  height: auto!important;
  overflow: visible !important;
}
}
.layoutJSON {
  /* background: ##F4F4F8 0% 0% no-repeat padding-box; */
  /* padding: 4rem; */
  background: #f4f4f8;
  box-shadow: -1px 1px 0px 0px #e4e4e4;
  font-size: 20px;
}

.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
.react-grid-item {
  box-sizing: border-box;
}
.react-grid-item:not(.react-grid-placeholder) {
  background: #f4f4f8;
  /* border: 1px solid black; */
}
.react-grid-item.resizing {
  opacity: 0.9;
}
.react-grid-item.static {
  background: #f4f4f8;
}
.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}
.react-grid-item .minMax {
  font-size: 12px;
}
.react-grid-item .add {
  cursor: pointer;
}
.react-grid-dragHandleExample {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
li b {
  font-size: 19px;
  line-height: 14px;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
  @media print {
    display: block !important;
      width: auto !important;
      height: auto !important;
      overflow: visible !important;
      position: unset;
  }
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}
.not-editable >.react-grid-item > .react-resizable-handle {
  display: none;
}

.buttonWrapper {
  display: flex;
  margin-top: 4rem;
  align-items: center;
  justify-content: space-between;
  
}

.dxg-scale {
  display: none;
}

.dxg-background-range {
  display: none;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.spred::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.spred {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.dx-area-field{
  font-size: large;
}
.dx-item-content{
  font-size: large;
}
.dx-item-content td{
  font-size: large;
}
.dx-word-wrap{
  font-size: large;
}
.dx-area-caption{
  font-size: large;
}
.dx-pivotgrid-area td{
  font-size: 2.5rem;
}
.dx-pivotgrid-area tr{
font-size: 2.5rem;
}